import type {
    DashboardBusinessRemovedImageData,
    BusinessCategory,
    BusinessCategoryTree,
    BusinessCoupon,
    BusinessCouponOrder,
    BusinessInstantSearchSuggestionsData,
    BusinessReview,
    BusinessSubscriptionPlan,
    DashboardBusiness,
    DashboardBusinessCategory,
    DashboardBusinessEditPermissions,
    DashboardBusinessImagesData,
    DashboardBusinessNonSelectedProductsAndServices,
    DashboardBusinessPaymentMethod,
    DashboardBusinessQuota,
    DashboardBusinessSubscriptionDetails,
    DashboardBusinessSubscriptionPlan,
    DashboardBusinessTimingData,
    DashboardBusinessesData,
    DashboardGivenBusinessReviewsData,
    DashboardLikedBusiness,
    DashboardLikedBusinessesData,
    DashboardMyAvailedBusinessCouponOrdersData,
    DashboardMyBusinessReviewsData,
    MainBusinessData,
    MainBusinessesHomeData,
    MyBusinessReview,
    Business
} from "~/types";

export const useBusinessesDataService = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const refreshBusinessesHook = ref<Function | null>(null);
    /* Dashboard Businesses */
    const fetchBusinessesDataForDashboardByFilter = async () => {
        const {data, error, refresh} = await useAsyncData(`${route.query.filter ?? 'active'}-businesses`,
            async () => {
                const response = await useNuxtApp().$getMyBusinessesByFilter((route.query.filter as string) ?? 'active')
                return {
                    businesses: response.data.data,
                    pagination_meta_data: response.data.meta,
                    pagination_links: response.data.links,
                    statistics: response.statistics,
                } as DashboardBusinessesData;
            }
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            refreshBusinessesHook.value = refresh;
            return data;
        }
    }

    const fetchBusinessesDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getBusinessesDataFromUrl(url);
            const businessesData = {
                businesses: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
                statistics: response.statistics,
            } as DashboardBusinessesData;

            return computed(() => businessesData);

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const requestToSubmitCurrentDashboardBusinessForApproval = async () => {
        try {
            const response = await nuxtApp.$submitMyBusinessForApprovalBySlug(route.params.slug as string);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToCreateNewBusiness = async (data: object) => {
        try {
            const response = await nuxtApp.$createMyBusiness(data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchBusinessResourceForDashboard = async (slug: string) => {
        const {data, error} = await useAsyncData('dashboard-business',
            () => nuxtApp.$getMyBusinessBySlug(slug)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as DashboardBusiness
        }
    };

    const requestToUpdateBusinessName = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessNameBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessSlogan = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessSloganBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessEstablishedYear = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessEstablishedYearBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessServiceArea = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessServiceAreaBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessDescription = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessDescriptionBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessFullAddress = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessFullAddressBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessWebsite = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessWebsiteBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessPhone = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessPhoneBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessCell = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessCellBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessFax = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessFaxBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessEmail = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessEmailBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessContactUsPageUrl = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessContactUsPageUrlBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessOwnerFullName = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessOwnerFullNameBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateBusinessAboutOwner = async (data: object) => {
        try {
            const response = await nuxtApp.$updateBusinessAboutOwnerBySlug(route.params.slug as string, data);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeactivateCurrentDashboardBusiness = async () => {
        try {
            const response = await nuxtApp.$deactivateMyBusinessBySlug(route.params.slug as string);
            return response.data as DashboardBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchCurrentDashboardBusinessQuota = async () => {
        try {
            const response = await nuxtApp.$getMyBusinessQuotaBySlug(route.params.slug as string);
            return response.data as DashboardBusinessQuota;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard Business End*/

    /*Dashboard Business Images*/
    const fetchCurrentDashboardBusinessImages = async () => {
        const {data, error, refresh} = await useAsyncData('dashboard-business-images',
            () => nuxtApp.$getMyBusinessImagesByBusinessSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed(() => {
                return {
                    business_images: data.value.data.business_images as string[],
                    permission: data.value.data.permission as boolean,
                    remaining_quota: data.value.data.remaining_quota as number,
                    total_quota: data.value.data.total_quota as number,
                } as DashboardBusinessImagesData
            });
        }
    }

    const requestToDeleteCurrentDashboardBusinessImageByIndex = async (index: number) => {
        try {
            const response = await nuxtApp.$deleteMyBusinessImageByBusinessImageIndex(route.params.slug as string, index);
            return response.data as DashboardBusinessRemovedImageData;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard Business Images End*/

    /* Dashboard Liked Business*/
    const fetchLikedBusinessesData = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-liked-businesses`,
            () => useNuxtApp().$getMyLikedBusinesses()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const pinnedNewsAnnouncementsData = computed<DashboardLikedBusinessesData>(() => {
                return {
                    liked_businesses: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                }
            });
            return ref({
                pinnedNewsAnnouncementsData,
                refresh
            });
        }
    };

    const fetchLikedBusinessesDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getMyLikedBusinessesDataFromUrl(url);
            return  {
                liked_businesses: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as DashboardLikedBusinessesData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }

    }

    const requestToLikeBusinessBySlug = async () => {
        try {
            const response = await nuxtApp.$likeBusinessBySlug(route.params.slug as string);
            return response.data as DashboardLikedBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUnLikeABusinessBySlug = async () => {
        try {
            const response = await nuxtApp.$unLikeBusinessBySlug(route.params.slug as string);
            return response.data as Business;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /* Dashboard Liked Business End*/

    /* Dashboard Given Business Reviews */
    const fetchGivenBusinessReviewsData = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-given-business-reviews`,
            () => useNuxtApp().$getMyGivenBusinessReviewsAndRatings()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const givenBusinessReviewsData = computed<DashboardGivenBusinessReviewsData>(() => {
                return {
                    given_business_reviews: data.value.data,
                    pagination_meta_data: data.value.meta,
                    pagination_links: data.value.links,
                }
            });
            return ref({
                givenBusinessReviewsData,
                refresh
            });
        }
    };

    const fetchGivenBusinessReviewsDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getMyLikedBusinessesDataFromUrl(url);
            const givenBusinessReviewsData = {
                given_business_reviews: response.data,
                pagination_meta_data: response.meta,
                pagination_links: response.links,
            } as DashboardGivenBusinessReviewsData;

            return givenBusinessReviewsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }

    }

    const requestToCreateBusinessReview = async (data: object) => {
        try {
            const response = await nuxtApp.$createMyGivenBusinessReviewsAndRatings(data);
            return response.data as BusinessReview;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard Given Business Reviews End*/

    /* Dashboard Availed Business Coupons */
    const fetchAvailedBusinessCouponsDataForDashboardByFilter = async () => {
        const {data, error, refresh} = await useAsyncData(`${route.query.filter ?? 'active'}-availed-business-coupons`,
            async () => {
                const response = await useNuxtApp().$getMyAvailedBusinessCoupons((route.query.filter as string) ?? 'active')
                return {
                    availed_business_coupons: response.data.data,
                    pagination_meta_data: response.data.meta,
                    pagination_links: response.data.links,
                    statistics: response.statistics,
                } as DashboardMyAvailedBusinessCouponOrdersData;
            }
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            refreshBusinessesHook.value = refresh;
            return data;
        }
    }

    const fetchAvailedBusinessCouponsDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getBusinessesDataFromUrl(url);
            const businessesData = {
                availed_business_coupons: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
                statistics: response.statistics,
            } as DashboardMyAvailedBusinessCouponOrdersData;

            return computed(() => businessesData);

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const requestToAvailBusinessCouponOrder = async (couponId: number, data: object) => {
        try {
            const response = await nuxtApp.$availBusinessCouponByCouponId(route.params.slug as string, couponId, data);
            return response.data as BusinessCouponOrder;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /* Dashboard Availed Business Coupons End*/

    /* Dashboard My Business Reviews*/
    const fetchMyBusinessReviewsData = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-my-business-reviews`,
            () => useNuxtApp().$getMyBusinessReviewsByBusinessSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const pinnedNewsAnnouncementsData = computed<DashboardMyBusinessReviewsData>(() => {
                return {
                    business_reviews: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                }
            });
            return ref({
                pinnedNewsAnnouncementsData,
                refresh
            });
        }
    };

    const fetchMyBusinessReviewsDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getMyPinnedNewsAnnouncementsDataFromUrl(url);
            const pinnedNewsAnnouncementsData = {
                business_reviews: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as DashboardMyBusinessReviewsData;

            return pinnedNewsAnnouncementsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }

    }

    const requestToCreateReplyOfABusinessReview = async (reviewId: number, data: object) => {
        try {
            const response = await nuxtApp.$replyMyBusinessReviewByBusinessReviewId(route.params.slug as string, reviewId, data);
            return response.data as MyBusinessReview;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateNewsAnnouncement = async (reviewId: number, data: object) => {
        try {
            const response = await nuxtApp.$updateMyBusinessReviewReplyByBusinessReviewId(route.params.slug as string, reviewId, data);
            return response.data as MyBusinessReview;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard My Business Reviews End*/

    /* Dashboard My Business Products and Services*/

    const fetchAllDashboardBusinessProductsAndServices = async () => {
        try {
            const response = await nuxtApp.$getMyBusinessProductsAndServicesByBusinessSlug(route.params.slug as string);
            return response.data as string[];
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchAllDashboardBusinessProductsAndServicesFromNonSelectedCategory = async (categoriesIds: string[]) => {
        try {
            const response = await nuxtApp.$obtainMyBusinessProductsAndServicesFromNonSelectedCategoryByBusinessSlug(
                route.params.slug as string, {
                    chosen_categories: categoriesIds
                });
            return response.data as DashboardBusinessNonSelectedProductsAndServices;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard My Business Products and Services End*/

    /*Dashboard My Business Timing */
    const fetchDashboardBusinessTiming = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-business-timing`,
            () => useNuxtApp().$getMyBusinessTimingByBusinessSlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {

            const dashboardBusinessTimingData = computed<DashboardBusinessTimingData>(() => {
                return {
                    business_timing: data.value.data.business_timing,
                    permission: data.value.data.permission,
                }
            });

            return ref({
                dashboardBusinessTimingData,
                refresh
            });
        }
    }

    const requestToUpdateDashboardBusinessTiming = async (data: object) => {
        try {
            const response = await nuxtApp.$updateMyBusinessTimingByBusinessSlug(route.params.slug as string, data);
            return response.data as DashboardBusinessTimingData;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /*Dashboard My Business Timing Ends */

    /*Dashboard My Business Subscription */

    const requestToCreateSubscriptionForBusiness = async (data: object) => {
        try {
            const response = await nuxtApp.$createSubscriptionForMyBusinessBySlug(route.params.slug as string, data);
            return response.data as DashboardBusinessSubscriptionPlan;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchDashboardBusinessSubscriptionDetails = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-business-subscription-details`,
            () => useNuxtApp().$getBusinessSubscriptionDetails(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const subscriptionDetails = data.value?.data as DashboardBusinessSubscriptionDetails;
            return ref({
                subscriptionDetails,
                refresh
            });
        }
    }

    const requestToCancelBusinessSubscription = async () => {
        try {
            const response = await useNuxtApp().$cancelBusinessSubscriptionBySlug(route.params.slug as string)
            return response.data as DashboardBusinessSubscriptionDetails;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const requestToResumeBusinessSubscription = async () => {
        try {
            const response = await useNuxtApp().$resumeBusinessSubscriptionBySlug(route.params.slug as string)
            return response.data as DashboardBusinessSubscriptionDetails;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const requestToSwitchBusinessSubscription = async (planId: number, pricingSlug: string, chosen_categories?: string[]) => {
        try {
            const response = await useNuxtApp().$switchBusinessSubscriptionByPlanAndBySlug(route.params.slug as string, planId, {
                pricing: pricingSlug,
                chosen_categories: chosen_categories
            })
            return response.data as DashboardBusinessSubscriptionDetails;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const requestToUpdatePaymentMethodOfABusiness = async (data: object) => {
        try {
            const response = await useNuxtApp().$updateBusinessPaymentMethod(route.params.slug as string, data)
            return response.data as DashboardBusinessPaymentMethod;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const requestToCheckIfSubscriptionIsDowngrading = async (planId: number) => {
        try {
            const response = await useNuxtApp().$checkIfDowngradingBusinessBySlug(route.params.slug as string, planId)
            return response.data.is_downgrading as boolean;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    /*Dashboard My Business Subscription Ends*/

    /*Dashboard My Business Categories*/
    const requestToAttachBusinessCategoryWithBusiness = async (attachableCategoryId: number) => {
        try {
            const response = await nuxtApp.$attachBusinessCategoryByMyBusinessSlug(route.params.slug as string, {category_id: attachableCategoryId});
            return response.data as DashboardBusinessCategory[];
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToRemoveBusinessCategoryFromBusiness = async (removableCategoryId: number) => {
        try {
            const response = await nuxtApp.$removeBusinessCategoryByMyBusinessSlug(route.params.slug as string, {category_id: removableCategoryId});
            return response.data as DashboardBusinessCategory[];
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /*Dashboard My Business Categories Ends*/

    /*Dashboard My Business Basics*/
    const fetchDashboardBusinessPermissions = async () => {
        try {
            const response = await nuxtApp.$getMyBusinessEditingPermissionsBySlug(route.params.slug as string);
            return response.data as DashboardBusinessEditPermissions;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchDashboardBusinessQuotas = async () => {
        try {
            const response = await nuxtApp.$getMyBusinessEditingPermissionsBySlug(route.params.slug as string);
            return response.data as DashboardBusinessQuota;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /*Dashboard My Business Basics Ends*/

    /* Liked Businesses */
    const fetchLikedBusinessesDataForDashboard = async () => {
        const {data, error, refresh} = await useAsyncData(`liked-businesses`,
            () => useNuxtApp().$getMyLikedBusinesses()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed<DashboardLikedBusinessesData>(() => {
                return {
                    liked_businesses: data.value.data as DashboardLikedBusiness[],
                    pagination_meta_data: data.value.meta as PaginationMeta,
                    pagination_links: data.value.links as PaginationLinks,
                }
            });
        }
    }

    const fetchLikedBusinessesDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getBusinessesDataFromUrl(url);
            return {
                liked_businesses: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as DashboardLikedBusinessesData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const requestToUnLikeBusiness = async (slug: string) => {
        try {
            const response = await nuxtApp.$unLikeBusinessBySlug(slug);
            return response.data as Business;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /* Liked Businesses Ends*/
    
    /*Main Business*/
    const fetchBusinessSubscriptionPlan = async () => {
        try {
            const response = await nuxtApp.$getBusinessSubscriptionPlanBySlug(route.query.plan as string);
            return response.data as BusinessSubscriptionPlan;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchBusiness = async () => {
        const {data, error} = await useAsyncData('business',
            () => nuxtApp.$getBusinessBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as MainBusinessData;
        }
    }

    const fetchBusinessesDataForHome = async () => {
        const {data, error, refresh} = await useAsyncData(`home-businesses-data`,
            () => useNuxtApp().$getBusinessesHomeData()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const filteredBusinessesData = computed<MainBusinessesHomeData>(() => {
                return data.value
            });

            return ref({
                filteredBusinessesData,
                refresh
            });
        }
    }

    const fetchBusinessCouponById = async (couponId: number) => {
        const {data, error} = await useAsyncData('business-coupon',
            () => nuxtApp.$getBusinessCouponById(couponId)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as BusinessCoupon;
        }
    }

    const fetchBusinessMajorCategoryWithSubCategories = async (couponId: number) => {
        const {data, error} = await useAsyncData('business-major-categories-with-subcategories',
            () => nuxtApp.$getBusinessMajorCategoryWithSubCategories()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as BusinessCategoryTree;
        }
    }

    const fetchBusinessSubCategoriesByParentCategory = async (categoryId: number) => {
        try {
            const response = await nuxtApp.$getBusinessSubCategoriesByParentCategoryId(categoryId);
            return response.data as BusinessCategory[];
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToCreateBusinessReport = async (reportData: object) => {
        try {
            const results = () => nuxtApp.$createBusinessReport(reportData)
            return results;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    /*Main Business Ends*/

    /*Business Search*/

    const fetchInstantSearchResultsForBusinessesByQuery = async (query: string) => {
        try {
            const response = await useNuxtApp().$getInstantSearchResultsForBusinessesByQuery(query);
            const instantSuggestions = {
                business_categories_results: response.business_categories_results,
                business_products_results: response.business_products_results,
                business_services_results: response.business_services_results,
                business_custom_products_results: response.business_custom_products_results,
                business_custom_services_results: response.business_custom_services_results,
                business_amenities_results: response.business_amenities_results,
                business_results: response.business_results,
            } as BusinessInstantSearchSuggestionsData;

            return instantSuggestions;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const requestToSearchBusinessesByCriteria = async (query: string) => {
        // const { data, error } = await useAsyncData('searched-news-announcements',
        //     () => nuxtApp.$searchNewsAnnouncementsByCriteria(query)
        // );

        // if (error.value != null) {
        //     setError(error.value.data as ApiError);
        //     return null;
        // } else {
        //     return data.value.data as NewsPostCategory;
        // }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {

        fetchBusinessesDataForDashboardByFilter,
        refreshBusinessesHook,
        fetchBusinessesDataForDashboardFromUrl,
        requestToSubmitCurrentDashboardBusinessForApproval,
        requestToCreateNewBusiness,
        fetchBusinessResourceForDashboard,
        requestToUpdateBusinessName,
        requestToUpdateBusinessSlogan,
        requestToUpdateBusinessEstablishedYear,
        requestToUpdateBusinessServiceArea,
        requestToUpdateBusinessDescription,
        requestToUpdateBusinessFullAddress,
        requestToUpdateBusinessWebsite,
        requestToUpdateBusinessPhone,
        requestToUpdateBusinessCell,
        requestToUpdateBusinessFax,
        requestToUpdateBusinessEmail,
        requestToUpdateBusinessContactUsPageUrl,
        requestToUpdateBusinessOwnerFullName,
        requestToUpdateBusinessAboutOwner,
        requestToDeactivateCurrentDashboardBusiness,
        fetchCurrentDashboardBusinessQuota,

        fetchCurrentDashboardBusinessImages,
        requestToDeleteCurrentDashboardBusinessImageByIndex,

        fetchLikedBusinessesData,
        fetchLikedBusinessesDataFromUrl,
        requestToLikeBusinessBySlug,
        requestToUnLikeABusinessBySlug,

        fetchGivenBusinessReviewsData,
        fetchGivenBusinessReviewsDataFromUrl,
        requestToCreateBusinessReview,

        fetchMyBusinessReviewsData,
        fetchMyBusinessReviewsDataFromUrl,
        requestToCreateReplyOfABusinessReview,
        requestToUpdateNewsAnnouncement,

        fetchAllDashboardBusinessProductsAndServices,
        fetchAllDashboardBusinessProductsAndServicesFromNonSelectedCategory,

        fetchDashboardBusinessTiming,
        requestToUpdateDashboardBusinessTiming,

        requestToCreateSubscriptionForBusiness,
        fetchDashboardBusinessSubscriptionDetails,
        requestToCancelBusinessSubscription,
        requestToResumeBusinessSubscription,
        requestToSwitchBusinessSubscription,
        requestToUpdatePaymentMethodOfABusiness,
        requestToCheckIfSubscriptionIsDowngrading,

        requestToAttachBusinessCategoryWithBusiness,
        requestToRemoveBusinessCategoryFromBusiness,

        fetchDashboardBusinessPermissions,
        fetchDashboardBusinessQuotas,

        fetchBusinessSubscriptionPlan,
        fetchBusiness,
        fetchBusinessesDataForHome,
        fetchBusinessCouponById,
        fetchBusinessMajorCategoryWithSubCategories,
        fetchBusinessSubCategoriesByParentCategory,
        requestToCreateBusinessReport,

        fetchInstantSearchResultsForBusinessesByQuery,
        requestToSearchBusinessesByCriteria,


        fetchLikedBusinessesDataForDashboard,
        fetchLikedBusinessesDataForDashboardFromUrl,
        requestToUnLikeBusiness,

        fetchAvailedBusinessCouponsDataForDashboardByFilter,
        fetchAvailedBusinessCouponsDataForDashboardFromUrl,
        requestToAvailBusinessCouponOrder,
    }
}