import type {
    BusinessSubscriptionPlan,
    BusinessSubscriptionPlanPrice,
    DashboardBusiness,
    DashboardBusinessAttachedNewImageData,
    DashboardBusinessImagesData,
    DashboardBusinessImagesStoreData,
    DashboardBusinessRemovedImageData,
} from "~/types";

export const useBusinessesDataManager = () => {
    const route = useRoute();
    const businessesStore = useBusinesses();
    const businessesDataService = useBusinessesDataService();

    /* Dashboard Businesses*/
    const getDashboardBusinessesByFilter = async () => {
        return await businessesDataService.fetchBusinessesDataForDashboardByFilter();
    }

    const getDashboardBusinessesFromUrl = async (url: string) => {
        const results = await businessesDataService.fetchBusinessesDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return results;
    }

    const getCurrentDashboardBusiness = async () => {
        const storedBusiness = computed(() => {
            return businessesStore.current_dashboard_business;
        })

        const slug = route.params.slug as string;
        if ((storedBusiness.value == null || storedBusiness.value.id == null || storedBusiness.value.slug != route.params.slug || !businessesStore.isCurrentDashboardBusinessValid()) && slug) {
            const business = await businessesDataService.fetchBusinessResourceForDashboard(slug);
            if (business)
                businessesStore.setCurrentDashboardBusiness(business);
        }

        return storedBusiness;
    }


    const createNewBusiness = async (data: object) => {
        const createdBusiness = await businessesDataService.requestToCreateNewBusiness(data);
        if (createdBusiness) {
            businessesStore.setCurrentDashboardBusiness(createdBusiness);
            useAlertSetter().setSuccessAlert("Business Listing Created Successfully");
            navigateTo({
                name: "dashboard-businesses",
                query: {
                    filter: "active",
                }
            });
        }
    };

    const attachBusinessCategory = async (attachableBusinessCategoryId: string) => {
        const updatedBusinessCategories = await businessesDataService.requestToAttachBusinessCategoryWithBusiness(parseInt(attachableBusinessCategoryId));
        if (updatedBusinessCategories) {
            businessesStore.updateCurrentDashboardBusinessCategories(updatedBusinessCategories);
        }
        return updatedBusinessCategories;
    };

    const removeBusinessCategory = async (removableBusinessCategoryId: string) => {
        const updatedBusinessCategories = await businessesDataService.requestToRemoveBusinessCategoryFromBusiness(parseInt(removableBusinessCategoryId));
        if (updatedBusinessCategories) {
            businessesStore.updateCurrentDashboardBusinessCategories(updatedBusinessCategories);
        }
        return updatedBusinessCategories;
    };


    const submitCurrentDashboardBusinessForApproval = async () => {
        const submittedDashboardBusiness = await businessesDataService.requestToSubmitCurrentDashboardBusinessForApproval();
        if (submittedDashboardBusiness) {
            businessesStore.setCurrentDashboardBusiness(submittedDashboardBusiness);
            navigateTo({
                name: "dashboard-businesses-slug",
                params: {
                    slug: submittedDashboardBusiness.slug,
                },
            });
        }
    };

    const updateBusinessName = async (data: object, shouldRedirect: boolean) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessName(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, true);
            return true;
        }
        return false;
    };

    const updateBusinessSlogan = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessSlogan(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessEstablishedYear = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessEstablishedYear(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessServiceArea = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessServiceArea(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessDescription = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessDescription(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessWebsite = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessWebsite(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessPhone = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessPhone(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessCell = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessCell(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessFax = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessFax(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessEmail = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessEmail(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessContactUsPageUrl = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessContactUsPageUrl(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessOwnerFullName = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessOwnerFullName(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessAboutOwner = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessAboutOwner(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, false);
            return true;
        }
        return false;
    };

    const updateBusinessFullAddress = async (data: object) => {
        const updatedBusiness = await businessesDataService.requestToUpdateBusinessFullAddress(data);
        if (updatedBusiness) {
            makeActionsAfterUpdate(updatedBusiness, true);
            return true;
        }
        return false;
    };

    const makeActionsAfterUpdate = (updatedBusiness: DashboardBusiness, shouldRedirect: boolean) => {
        businessesStore.setCurrentDashboardBusiness(updatedBusiness);
        useAlertSetter().setSuccessAlert("Business Listing Updated Successfully");
        if (shouldRedirect) {
            navigateTo({
                name: "dashboard-businesses-slug",
                params: {slug: businessesStore.current_dashboard_business?.slug as string},
            })
        }
    }

    const getMyBusinessEditingPermissions = async () => {
        const storedBusinessPermissions = computed(() => {
            return businessesStore.current_dashboard_business_editing_permissions;
        })

        if ((storedBusinessPermissions.value == null || !businessesStore.isCurrentDashboardBusinessEditingPermissionsValid())) {
            const businessPermissions = await businessesDataService.fetchDashboardBusinessPermissions();
            if (businessPermissions)
                businessesStore.setCurrentDashboardBusinessEditingPermissions(businessPermissions);
        }

        return storedBusinessPermissions;
    };


    const deactivateCurrentDashboardBusiness = async () => {
        const deactivatedBusiness = await businessesDataService.requestToDeactivateCurrentDashboardBusiness();
        if (deactivatedBusiness) {
            businessesStore.setCurrentDashboardBusiness(deactivatedBusiness)
            navigateTo({
                name: "dashboard-businesses-slug",
                params: {
                    slug: deactivatedBusiness.slug
                }
            });
        }
    };

    const getDashboardBusinessQuota = async () => {
        const businessQuota = await businessesDataService.fetchCurrentDashboardBusinessQuota();
        if (businessQuota) {
            return businessQuota;
        }
    };
    /* Dashboard Businesses Ends*/

    /* Dashboard Business Timing*/
    const getCurrentDashboardBusinessTiming = async () => {

        const storedBusinessTiming = computed(() => {
            return businessesStore.current_dashboard_business_timing;
        })

        if (storedBusinessTiming.value.timing == null || !businessesStore.isCurrentDashboardBusinessTimingValid()) {
            const businessTimingData = await businessesDataService.fetchDashboardBusinessTiming();
            if (businessTimingData?.value) {
                businessesStore.setCurrentDashboardBusinessTimingData(
                    businessTimingData.value.dashboardBusinessTimingData,
                );
            }
        }
        return storedBusinessTiming;
    }

    const updateBusinessTiming = async (data: object) => {
        const updatedBusinessTimingData = await businessesDataService.requestToUpdateDashboardBusinessTiming(data);
        if (updatedBusinessTimingData) {
            businessesStore.setCurrentDashboardBusinessTimingData(updatedBusinessTimingData);
            useAlertSetter().setSuccessAlert("Successfully Updated Business Timing");
        }
    };


    /* Dashboard Business Timing Ends*/

    /*Dashboard Business Images*/
    const getCurrentDashboardBusinessImagesData = async () => {
        const storedBusinessImagesData = businessesStore.current_dashboard_business_images as DashboardBusinessImagesStoreData;
        if (storedBusinessImagesData == null || storedBusinessImagesData.images.length == 0 || !businessesStore.isCurrentDashboardBusinessImagesValid()) {
            const data = await businessesDataService.fetchCurrentDashboardBusinessImages();
            if (data?.value)
                businessesStore.setCurrentDashboardBusinessImagesData(data.value);
        }

        return computed(() => businessesStore.businessImagesData as DashboardBusinessImagesData);
    }

    const addImageToCurrentDashboardBusinessImages = (newImageData: DashboardBusinessAttachedNewImageData) => {
        businessesStore.updateCurrentDashboardBusinessImagesDataAfterAttachment(newImageData);
        useAlertSetter().setSuccessAlert("Successfully added image for business listing");
    }

    const removeImageFromCurrentDashboardBusinessImagesAtIndex = async (imageIndex: number) => {
        const results = await businessesDataService.requestToDeleteCurrentDashboardBusinessImageByIndex(imageIndex);
        if (results)
            businessesStore.updateCurrentDashboardBusinessImagesDataAfterRemovalAtIndex(imageIndex, results as DashboardBusinessRemovedImageData);
        useAlertSetter().setSuccessAlert("Successfully removed image from business listing");
    }
    /*Dashboard Business Images End*/

    const getDashboardBusinessProductsAndServices = async () => {
        return await businessesDataService.fetchAllDashboardBusinessProductsAndServices();
    }


    /* Dashboard Business Subscription*/
    const getCurrentDashboardSubscriptionDetails = async () => {
        const businessSubscriptionDetails = await businessesDataService.fetchDashboardBusinessSubscriptionDetails();
        return businessSubscriptionDetails;
    }

    const createSubscriptionForCurrentDashboardBusiness = async (data: object) => {
        const createdSubscriptionPlan = await businessesDataService.requestToCreateSubscriptionForBusiness(data);
        if (createdSubscriptionPlan) {
            navigateTo({
                name: "dashboard-businesses-slug-billing",
                params: {
                    slug: route.params.slug
                }
            });
            useAlertSetter().setSuccessAlert("Successfully Subscribed!");
        }
    }

    const getABusinessSubscriptionPlan = async () => {
        const businessSubscriptionPlan = await businessesDataService.fetchBusinessSubscriptionPlan();
        return businessSubscriptionPlan;
    }

    const cancelCurrentDashboardSubscription = async () => {
        const cancelledPlan = await businessesDataService.requestToCancelBusinessSubscription();
        return cancelledPlan;
    }

    const resumeCurrentDashboardSubscription = async () => {
        const resumedBusinessSubscriptionDetails = await businessesDataService.requestToResumeBusinessSubscription();
        return resumedBusinessSubscriptionDetails;
    }

    const checkIfSubscriptionDowngrading = async (plan: BusinessSubscriptionPlan) => {
        const isDowngrading = await businessesDataService.requestToCheckIfSubscriptionIsDowngrading(parseInt(plan.id));
        if (isDowngrading) {
            navigateTo({
                name: "dashboard-businesses-slug-billing-downgrade",
                params: {
                    slug: useRoute().params.slug
                },
                query: {
                    plan: plan.slug
                }
            });
            return true;
        } else {
            return false;
        }
    }

    const updateCurrentDashboardBusinessPaymentMethod = async (data: object) => {
        const updatedPaymentMethod = await businessesDataService.requestToUpdatePaymentMethodOfABusiness(data);
        if (updatedPaymentMethod) {
            useAlertSetter().setSuccessAlert("Successfully Updated Payment Method!");
            navigateTo({
                name: "dashboard-businesses-slug-billing",
                params: {
                    slug: route.params.slug
                }
            });
        }
        return updatedPaymentMethod
    }

    const switchCurrentBusinessSubscriptionPlan = async (plan: BusinessSubscriptionPlan, pricing: BusinessSubscriptionPlanPrice, chosenCategoriesIds?: string[]) => {
        const switchedSubscriptionDetails = await businessesDataService.requestToSwitchBusinessSubscription(parseInt(plan.id), pricing.slug, chosenCategoriesIds);
        if (switchedSubscriptionDetails) {
            useAlertSetter().setSuccessAlert("Successfully switched subscription plan!");
            navigateTo({
                name: "dashboard-businesses-slug-billing",
                params: {
                    slug: route.params.slug
                }
            });
        }
        return switchedSubscriptionDetails;
    }

    const getAllDashboardBusinessProductsAndServicesFromNonSelectedCategory = async (chosenCategoriesIds: string[]) => {
        const productsAndServices = await businessesDataService.fetchAllDashboardBusinessProductsAndServicesFromNonSelectedCategory(chosenCategoriesIds);
        return productsAndServices;
    }

    /* Dashboard Business Subscription Ends*/


    /* Liked Businesses*/
    const getDashboardLikedBusinesses = async () => {
        const dashboardData = await businessesDataService.fetchLikedBusinessesDataForDashboard();
        return computed(() => dashboardData?.value)
    }

    const getDashboardLikedBusinessesFromUrl = async (url: string) => {
        const results = await businessesDataService.fetchLikedBusinessesDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    const unLikeBusiness = async (slug: string) => {

        const unLikedBusiness = await businessesDataService.requestToUnLikeBusiness(slug);
        if (unLikedBusiness) {
            useAlertSetter().setSuccessAlert("Successfully removed business from liked list");
            return unLikedBusiness;
        }
        return null;
    };
    /* Liked Businesses Ends*/

    /* Business Reviews */
    const getDashboardGivenBusinessReviews = async () => {
        const dashboardData = await businessesDataService.fetchGivenBusinessReviewsData();
        return computed(() => dashboardData?.value.givenBusinessReviewsData)
    }

    const getDashboardGivenBusinessReviewsFromUrl = async (url: string) => {
        const results = await businessesDataService.fetchGivenBusinessReviewsDataFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }
    /*  Business Reviews */

    /* Business Availed Coupons*/
    const getDashboardAvailedBusinessCouponsByFilter = async () => {
        return await businessesDataService.fetchAvailedBusinessCouponsDataForDashboardByFilter();
    }

    const getDashboardAvailedBusinessCouponsFromUrl = async (url: string) => {
        const results = await businessesDataService.fetchAvailedBusinessCouponsDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return results;
    }
    /* Business Availed Coupons */


    /*Main Business*/
    const getBusinessSubCategoryByParentCategoryId = async (id: string) => {
        const subCategory = await businessesDataService.fetchBusinessSubCategoriesByParentCategory(parseInt(id));
        return computed(() => subCategory)
    }
    /*Main Business Ends*/

    return {

        getDashboardBusinessesByFilter,
        refreshBusinesses: businessesDataService.refreshBusinessesHook,
        getDashboardBusinessesFromUrl,

        getCurrentDashboardBusiness,
        getCurrentDashboardBusinessTiming,
        updateBusinessTiming,
        deactivateCurrentDashboardBusiness,
        submitCurrentDashboardBusinessForApproval,
        createNewBusiness,
        getMyBusinessEditingPermissions,
        attachBusinessCategory,
        removeBusinessCategory,
        updateBusinessName,
        updateBusinessSlogan,
        updateBusinessEstablishedYear,
        updateBusinessServiceArea,
        updateBusinessDescription,
        updateBusinessWebsite,
        updateBusinessPhone,
        updateBusinessCell,
        updateBusinessFax,
        updateBusinessEmail,
        updateBusinessContactUsPageUrl,
        updateBusinessOwnerFullName,
        updateBusinessAboutOwner,
        updateBusinessFullAddress,
        getDashboardBusinessQuota,

        getCurrentDashboardBusinessImagesData,
        addImageToCurrentDashboardBusinessImages,
        removeImageFromCurrentDashboardBusinessImagesAtIndex,

        getCurrentDashboardSubscriptionDetails,
        resumeCurrentDashboardSubscription,
        cancelCurrentDashboardSubscription,
        updateCurrentDashboardBusinessPaymentMethod,
        checkIfSubscriptionDowngrading,
        getABusinessSubscriptionPlan,
        createSubscriptionForCurrentDashboardBusiness,
        switchCurrentBusinessSubscriptionPlan,

        getAllDashboardBusinessProductsAndServicesFromNonSelectedCategory,

        getBusinessSubCategoryByParentCategoryId,

        getDashboardBusinessProductsAndServices,


        getDashboardLikedBusinesses,
        getDashboardLikedBusinessesFromUrl,
        unLikeBusiness,

        getDashboardGivenBusinessReviews,
        getDashboardGivenBusinessReviewsFromUrl,

        getDashboardAvailedBusinessCouponsByFilter,
        getDashboardAvailedBusinessCouponsFromUrl,
    }
}